// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// flatpickr
@import "../../node_modules/flatpickr/dist/themes/airbnb.css";
